import React from 'react'
import "../css/training.css"

const Training = () => {
  return (
    <div className='training'>
        <div className='head'><h3>Our Training Program</h3></div>
        <div>
        <p><strong>ValidationRecruiter.Com:</strong> has collaborated and partnered with 500+ esteemed companies in the USA to support individuals seeking free online engineering training tailored for industries such as Engineering, Medical Devices, Pharmaceuticals, Biotechnology, Automobiles, and more.</p>
        <p><strong>Training Timings:</strong> 8 PM to 9 PM EST (New York Time Zone), Monday to Friday.</p>
        <p><strong>Training Duration:</strong>  Two Weeks (10 days).</p>
        <p><strong>Who can apply and join? </strong>Any individual seeking free online Engineering training can be enrolled.</p>
        <p><strong>Application:  </strong>If you're keen on seizing this opportunity, kindly share a copy of your resume with us at,<a href='mailto:validationrecruiter.com@gmail.com'>validationrecruiter.com@gmail.com</a>  with <strong>"Training Code 101" </strong> in the email subject. Enroll today and embark on a journey of professional development without any delay!</p>
        <p><strong>Results:  </strong>validationrecruiter has enrolled over 5000 individuals for engineering training, and many have successfully secured jobs across various industries within the USA.

</p>
</div>
    </div>
  )
}

export default Training