// src/jobsData.js
const jobsData = [
    {
        title: "Mechanical Engineer",
        code: "001",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as a mechanical engineer",
        description: `As a Mechanical Engineer, you will be responsible for designing, developing, and testing mechanical systems and components. You will collaborate with cross-functional teams to ensure the reliability, efficiency, and safety of mechanical designs. Your role will involve troubleshooting and resolving mechanical issues, conducting performance tests, and ensuring compliance with industry standards.`,
        responsibilities:"Design, develop, and test mechanical systems and components Collaborate with cross-functional teams to optimize mechanical desigTroubleshoot and resolve mechanical issues in products and systeConduct performance tests and analyze test daEnsure compliance with industry standards and regulatioPrepare detailed technical reports and documentatiSupport manufacturing teams with process improvements and technical guidanParticipate in design reviews and provide feedback on mechanical aspects.",
        education: "Requires Bachelor's or Master’s in Mechanical Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Electrical Engineer",
        code: "002",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an electrical engineer",
        description: ` As an Electrical Engineer, you will be responsible for designing, developing, and testing electrical systems and components. You will collaborate with cross-functional teams to ensure the reliability, efficiency, and safety of electrical designs. Your role will involve troubleshooting and resolving electrical issues, conducting performance tests, and ensuring compliance with industry standards. `,
        responsibilities:"Design, develop, and test electrical systems and components.Collaborate with cross-functional teams to optimize electrical designs.Troubleshoot and resolve electrical issues in products and systems.Conduct performance tests and analyze test data.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on electrical aspects ",
        education: "Requires Bachelor's or Master’s in Electrical Engineer, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
      {
        title: "Validation Engineer",
        code: "003",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Validation Engineer",
        description: ` As a Validation Engineer, you will be responsible for ensuring that systems, processes, and products meet specified requirements and standards. You will work closely with cross-functional teams to develop validation protocols, execute tests, and analyze results to ensure quality and compliance. Your role will involve identifying and resolving validation issues, documenting findings, and continuously improving validation processes. `,
        responsibilities:" Develop and implement validation protocols for systems, processes, and products.Execute validation tests and analyze results to ensure compliance with specifications and standards.Collaborate with cross-functional teams to address validation issues and optimize processes.Troubleshoot and resolve validation issues in a timely manner.Prepare detailed validation reports and documentation.Ensure compliance with industry regulations and standards.Support continuous improvement initiatives for validation processes.Participate in design and technical reviews, providing validation expertise and feedback.",
        education: "Requires Bachelor's or Master’s in Validation Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Biomedical Engineer",
        code: "004",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Biomedical Engineer",
        description: `As a Biomedical Engineer, you will be responsible for designing, developing, and testing biomedical devices and systems. You will collaborate with cross-functional teams to ensure the reliability, efficiency, and safety of biomedical designs. Your role will involve troubleshooting and resolving biomedical issues, conducting performance tests, and ensuring compliance with industry standards. Additionally, you will prepare detailed technical reports and documentation, support manufacturing teams with process improvements and technical guidance, and participate in design reviews to provide feedback on biomedical aspects.`,
        responsibilities:"You will design, develop, and test biomedical devices and systems while collaborating with cross-functional teams to optimize biomedical designs. Your role includes troubleshooting and resolving issues in biomedical products and systems, conducting performance tests, and analyzing test data. Ensuring compliance with industry standards and regulations is crucial, and you will prepare detailed technical reports and documentation to support this. You will also support manufacturing teams with process improvements and technical guidance and participate in design reviews to provide feedback on biomedical aspects. ",
        education: "Requires Bachelor's or Master’s in Biomedical Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Chemical Engineer",
        code: "005",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Chemical Engineer",
        description: ` As a Chemical Engineer, you will be responsible for designing, developing, and optimizing chemical processes and systems. You will collaborate with cross-functional teams to ensure the efficiency, safety, and sustainability of chemical production. Your role will involve troubleshooting and resolving chemical process issues, conducting performance tests, and ensuring compliance with industry standards and regulations. `,
        responsibilities:"Design, develop, and optimize chemical processes and systems.Collaborate with cross-functional teams to enhance chemical production efficiency.Troubleshoot and resolve chemical process issues in products and systems.Conduct performance tests and analyze test data.Ensure compliance with industry standards and environmental regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on chemical process aspects. ",
        education: "Requires Bachelor's or Master’s in Chemical Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Industrial Engineer",
        code: "006",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Industrial Engineer",
        description: ` As an Industrial Engineer, you will be responsible for designing, developing, and optimizing production processes and systems. You will work closely with cross-functional teams to enhance operational efficiency, productivity, and quality. Your role will include analyzing workflows, identifying areas for improvement, and implementing effective solutions. Additionally, you will be involved in conducting time studies, developing process documentation, and ensuring compliance with industry standards.`,
        responsibilities:"Design, develop, and optimize production processes and systems. Collaborate with cross-functional teams to enhance operational efficiency and productivity. Troubleshoot and resolve issues in production workflows. Conduct time studies and analyze process data. Ensure compliance with industry standards and regulations. Prepare detailed technical reports and documentation. Support manufacturing teams with process improvements and technical guidance. Participate in design reviews and provide feedback on production processes.",
        education: "Requires Bachelor's or Master’s in Industrial Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Mechatronics Engineer",
        code: "007",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Mechatronics Engineer",
        description: ` As a Mechatronics Engineer, you will be at the forefront of designing, developing, and testing innovative mechatronic systems. Your role will encompass collaborating with multidisciplinary teams to ensure the seamless integration and functionality of mechanical, electrical, and computer components within these systems. You will play a crucial part in enhancing the efficiency, reliability, and safety of mechatronic designs through rigorous testing and compliance with industry standards`,
        responsibilities:"Design, develop, and test mechatronic systems to meet project requirements.Collaborate with cross-functional teams to optimize the design and functionality of mechatronic components.Troubleshoot and resolve issues related to the performance and integration of mechatronic products and systems.Conduct comprehensive performance tests and analyze test data to validate system functionality.Ensure strict compliance with industry standards and regulatory requirements throughout the development process.Prepare detailed technical reports and documentation to communicate system specifications and performance metrics.Provide technical support and guidance to manufacturing teams to facilitate process improvements and ensure technical accuracy.Participate actively in design reviews and contribute valuable feedback on mechatronic aspects of the project. ",
        education: "Requires Bachelor's or Master’s in Mechatronics Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Materials Engineer",
        code: "008",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Materials Engineer",
        description: ` As a Materials Engineer, you will play a crucial role in the development and improvement of materials to enhance the performance, durability, and cost-effectiveness of products. You will work closely with cross-functional teams to conduct research, analyze material properties, and develop innovative solutions for various applications. Your responsibilities will include testing materials, ensuring compliance with industry standards, and providing technical support throughout the production process. You will also be involved in troubleshooting material-related issues and implementing process improvements to optimize manufacturing efficiency. `,
        responsibilities:"As a Materials Engineer, you will be responsible for developing and testing materials to ensure they meet specific performance criteria. You will collaborate with cross-functional teams to analyze material properties and provide innovative solutions for product development. Your role will involve conducting research on new materials, testing and evaluating their performance, and ensuring compliance with industry standards. You will provide technical support to manufacturing teams, troubleshoot material-related issues, and implement process improvements to enhance production efficiency. Additionally, you will prepare detailed technical reports and documentation, and participate in design reviews to provide feedback on material selection and performance.",
        education: "Requires Bachelor's or Master’s in Materials Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Automobile Engineer",
        code: "009",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Automobile Engineer",
        description: ` As an Automobile Engineer, you will be responsible for designing, developing, and testing automotive systems and components. You will work closely with cross-functional teams to enhance the performance, safety, and efficiency of vehicle designs. Your role will include diagnosing and resolving automotive issues, conducting rigorous performance tests, and ensuring that all designs comply with industry standards and regulations. You will also prepare comprehensive technical reports and documentation, support manufacturing teams with process improvements, and participate in design reviews to provide critical feedback on automotive aspects.`,
        responsibilities:" As an Automobile Engineer, you will design, develop, and test automotive systems and components, collaborating with cross-functional teams to optimize vehicle designs. Your role will involve diagnosing and resolving automotive issues, conducting performance tests, and analyzing test data to ensure compliance with industry standards and regulations. You will prepare detailed technical reports and documentation, support manufacturing teams with process improvements and technical guidance, and actively participate in design reviews to provide feedback on automotive aspects.",
        education: "Requires Bachelor's or Master’s in Automobile Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Aerospace Engineer",
        code: "010",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Aerospace Engineer",
        description: `As an Aerospace Engineer, you will be at the forefront of designing, developing, and testing cutting-edge aerospace systems and components. You will work collaboratively with a team of professionals to enhance the efficiency, safety, and performance of aerospace technologies. Your role will include troubleshooting and resolving technical issues, conducting rigorous performance evaluations, and ensuring all designs meet industry standards and regulatory requirements.`,
        responsibilities:"In this role, you will design, develop, and rigorously test various aerospace systems and components, collaborating closely with cross-functional teams to ensure the highest standards in design optimization and innovation. You will troubleshoot and resolve any issues in aerospace systems, conduct detailed performance tests, and analyze the data to drive improvements. Ensuring compliance with industry standards and regulations, you will prepare comprehensive technical reports and documentation. Additionally, you will support manufacturing teams with process improvements and provide technical guidance, while actively participating in design reviews to offer critical feedback on aerospace aspects. ",
        education: "Requires Bachelor's or Master’s in Aerospace Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Systems Engineer ",
        code: "011",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Systems Engineer ",
        description: `As a Systems Engineer, you will be responsible for designing, developing, and testing complex systems. Collaboration with cross-functional teams will be crucial to ensure the reliability, efficiency, and safety of these systems. Your role will involve troubleshooting and resolving issues, conducting performance tests, and ensuring compliance with industry standards. `,
        responsibilities:"Design, develop, and test complex systems.Collaborate with cross-functional teams to optimize system designs.Troubleshoot and resolve issues in system products and components.Conduct performance tests and analyze test data.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on system aspects. ",
        education: "Requires Bachelor's or Master’s in Systems Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Civil Engineer  ",
        code: "012",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as anCivil Engineer  ",
        description: `As a Civil Engineer, you will be responsible for the design, construction, and maintenance of infrastructure projects. Your role involves ensuring the structural integrity, safety, and functionality of civil engineering projects. Collaborating with multidisciplinary teams, you will contribute to the successful completion of various construction projects.`,
        responsibilities:"Design, develop, and oversee construction of infrastructure projects.Collaborate with multidisciplinary teams to ensure project requirements are met.Troubleshoot and resolve issues related to construction projects.Conduct performance tests and analyze data to ensure project compliance.Ensure compliance with industry standards, regulations, and safety protocols.Prepare detailed technical reports and documentation for project documentation.Provide technical guidance and support to construction teams.Participate in project reviews and provide feedback on civil engineering aspects. ",
        education: "Requires Bachelor's or Master’s in Civil Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Environmental Engineer",
        code: "013",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an   Environmental Engineer ",
        description: `As an Environmental Engineer, you will be responsible for designing, implementing, and overseeing environmental projects and systems. Working collaboratively with diverse teams, you will ensure the effectiveness, sustainability, and compliance of environmental solutions. Your role will involve problem-solving, testing, and ensuring adherence to industry standards and regulations. `,
        responsibilities:"Design, develop, and test environmental projects and systems.Collaborate with cross-functional teams to optimize environmental designs.Troubleshoot and resolve issues in environmental products and systems.Conduct performance tests and analyze test data to assess environmental impact.Ensure compliance with industry standards, regulations, and environmental policies.Prepare detailed technical reports and documentation for environmental projects.Provide technical guidance and support to manufacturing teams for environmental solutions.Participate in design reviews and provide feedback on environmental aspects. ",
        education: "Requires Bachelor's or Master’s in   Environmental Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Nuclear Engineer",
        code: "014",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Nuclear Engineer ",
        description: `As a Nuclear Engineer, you will be responsible for designing, developing, and testing nuclear systems and technologies. You will collaborate closely with multidisciplinary teams to ensure the reliability, efficiency, and safety of nuclear designs. Your role will involve troubleshooting and resolving issues related to nuclear systems, conducting performance tests, and ensuring compliance with industry standards and regulations. `,
        responsibilities:"Design, develop, and test nuclear systems and technologies.Collaborate with cross-functional teams to optimize nuclear designs.Troubleshoot and resolve issues in nuclear products and systems.Conduct performance tests and analyze test data for nuclear systems.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation for nuclear projects.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on nuclear aspects.",
        education: "Requires Bachelor's or Master’s in Nuclear Engineer ing, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Electronics Engineer",
        code: "015",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Electronics Engineer",
        description: `As an Electronics Engineer, you will be instrumental in the design, development, and testing of electronic systems and components. Collaborating closely with cross-functional teams, you will ensure the reliability, efficiency, and safety of electronic designs. Your role will involve troubleshooting electronic issues, conducting performance tests, and ensuring compliance with industry standards. `,
        responsibilities:"Design, develop, and test electronic systems and components.Collaborate with cross-functional teams to optimize electronic designs.Troubleshoot and resolve issues in electronic products and systems.Conduct performance tests and analyze test data.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on electronic aspects. ",
        education: "Requires Bachelor's or Master’s in Electronics Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Robotics Engineer",
        code: "016",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Robotics Engineer",
        description: `As a Robotics Engineer, you will be responsible for the design, development, and testing of robotics systems and automation solutions. Collaborating with multidisciplinary teams, you will ensure the efficiency, reliability, and safety of robotics designs. Your role will involve troubleshooting, conducting performance tests, and ensuring compliance with industry standards.`,
        responsibilities:"Design, develop, and test robotics systems and automation solutions.Collaborate with cross-functional teams to optimize robotics designs.roubleshoot and resolve issues in robotics products and systems.Conduct performance tests and analyze test data to improve system efficiency.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on robotics aspects. ",
        education: "Requires Bachelor's or Master’s in Robotics Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Mobility Systems Engineer",
        code: "017",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Mobility Systems Engineer",
        description: `As a Mobility Systems Engineer, you will be responsible for designing, developing, and testing mobility systems and solutions. Collaborating closely with cross-functional teams, you will ensure the reliability, efficiency, and safety of mobility designs. Your role will involve troubleshooting and resolving issues related to mobility products and systems, conducting performance tests, and ensuring compliance with industry standards. `,
        responsibilities:"Design, develop, and test mobility systems and solutions.Collaborate with cross-functional teams to optimize mobility designs.Troubleshoot and resolve issues in mobility products and systems.Conduct performance tests and analyze test data.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on mobility aspects. ",
        education: "Requires Bachelor's or Master’s in Mobility Systems Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Aeronautical Engineer",
        code: "018",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Aeronautical Engineer",
        description: `As an Aeronautical Engineer, you will be responsible for the design, development, and testing of aircraft and aerospace systems. Working closely with multidisciplinary teams, you will ensure the efficiency, reliability, and safety of aerospace designs. Your role will involve troubleshooting complex issues, conducting performance tests, and ensuring compliance with industry standards and regulations.`,
        responsibilities:"Design, develop, and test aircraft and aerospace systems.Collaborate with cross-functional teams to optimize aerospace designs.Troubleshoot and resolve issues related to aircraft and aerospace products and systems.Conduct performance tests and analyze test data to ensure optimal functionality.Ensure compliance with industry standards and regulations in aerospace engineering.Prepare detailed technical reports and documentation for aerospace projects.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide valuable feedback on aerospace aspects. ",
        education: "Requires Bachelor's or Master’s in Aeronautical Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Biotechnology Engineer",
        code: "019",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Biotechnology Engineer",
        description: `As a Biotechnology Engineer, you will be responsible for designing, developing, and testing biotechnological devices and systems. Collaboration with cross-functional teams will be essential to ensure the reliability, efficiency, and safety of biotechnological designs. Your role will involve troubleshooting and resolving issues related to biotechnological products, conducting performance tests, and ensuring compliance with industry standards. `,
        responsibilities:"Design, develop, and test biotechnological devices and systems.Collaborate with cross-functional teams to optimize biotechnological designs.Troubleshoot and resolve issues in biotechnological products and systems.Conduct performance tests and analyze test data.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on biotechnological aspects. ",
        education: "Requires Bachelor's or Master’s in Biotechnology Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Supply Chain Engineer",
        code: "020",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Supply Chain Engineer",
        description: `As an Electronics Engineer, you will be instrumental in the design, development, and testing of electronic systems and components. Collaborating closely with cross-functional teams, you will ensure the reliability, efficiency, and safety of electronic designs. Your role will involve troubleshooting electronic issues, conducting performance tests, and ensuring compliance with industry standards. `,
        responsibilities:"Design, develop, and test electronic systems and components.Collaborate with cross-functional teams to optimize electronic designs.Troubleshoot and resolve issues in electronic products and systems.Conduct performance tests and analyze test data.Ensure compliance with industry standards and regulations.Prepare detailed technical reports and documentation.Support manufacturing teams with process improvements and technical guidance.Participate in design reviews and provide feedback on electronic aspects. ",
        education: "Requires Bachelor's or Master’s in Supply Chain Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    {
        title: "Electronics Engineer",
        code: "021",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Electronics Engineer",
        description: `As a Supply Chain Engineer, you will play a crucial role in optimizing and managing the flow of goods, services, and information within the supply chain network. You will collaborate with cross-functional teams to ensure efficiency, reliability, and compliance with industry standards. Your responsibilities will include analyzing supply chain processes, identifying areas for improvement, and implementing solutions to enhance overall supply chain performance. `,
        responsibilities:"Design, develop, and optimize supply chain processes to meet operational requirements.Collaborate with cross-functional teams to improve supply chain efficiency and reliability.Identify issues within the supply chain network and implement solutions to address them.Conduct analysis of supply chain data to identify trends and areas for improvement.Ensure compliance with industry standards, regulations, and best practices.Preare detailed technical reports and documentation related to supply chain processes.Provide technical guidance and support to manufacturing teams for process improvements.Participate in supply chain strategy development and provide feedback on process optimization.",
        education: "Requires Bachelor's or Master’s in Electronics Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    
    {
        title: " Computer System Validation Engineer",
        code: "022",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an  Computer System Validation Engineer",
        description: `As a Supply Chain Engineer, you will play a crucial role in optimizing and managing the flow of goods, services, and information within the supply chain network. You will collaborate with cross-functional teams to ensure efficiency, reliability, and compliance with industry standards. Your responsibilities will include analyzing supply chain processes, identifying areas for improvement, and implementing solutions to enhance overall supply chain performance. `,
        responsibilities:"Design, develop, and optimize supply chain processes to meet operational requirements.Collaborate with cross-functional teams to improve supply chain efficiency and reliability.Identify issues within the supply chain network and implement solutions to address them.Conduct analysis of supply chain data to identify trends and areas for improvement.Ensure compliance with industry standards, regulations, and best practices.Preare detailed technical reports and documentation related to supply chain processes.Provide technical guidance and support to manufacturing teams for process improvements.Participate in supply chain strategy development and provide feedback on process optimization.",
        education: "Requires Bachelor's or Master’s in  Computer System Validation Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
    
    {
        title: "Process Engineer",
        code: "023",
        location: "100% Remote or Hybrid within the USA",
        experience: "1-7 years of experience as an Process Engineer",
        description: `As a Supply Chain Engineer, you will play a crucial role in optimizing and managing the flow of goods, services, and information within the supply chain network. You will collaborate with cross-functional teams to ensure efficiency, reliability, and compliance with industry standards. Your responsibilities will include analyzing supply chain processes, identifying areas for improvement, and implementing solutions to enhance overall supply chain performance. `,
        responsibilities:"Design, develop, and optimize supply chain processes to meet operational requirements.Collaborate with cross-functional teams to improve supply chain efficiency and reliability.Identify issues within the supply chain network and implement solutions to address them.Conduct analysis of supply chain data to identify trends and areas for improvement.Ensure compliance with industry standards, regulations, and best practices.Preare detailed technical reports and documentation related to supply chain processes.Provide technical guidance and support to manufacturing teams for process improvements.Participate in supply chain strategy development and provide feedback on process optimization.",
        education: "Requires Bachelor's or Master’s in Process Engineering, or a related field.",
        salary: "$65,000 to $93,184.00 per year, depending on experience.",
        application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
      },
      {
          title: "Quality Engineer",
          code: "024",
          location: "100% Remote or Hybrid within the USA",
          experience: "1-7 years of experience as an Quality Engineer",
          description: `As a Supply Chain Engineer, you will play a crucial role in optimizing and managing the flow of goods, services, and information within the supply chain network. You will collaborate with cross-functional teams to ensure efficiency, reliability, and compliance with industry standards. Your responsibilities will include analyzing supply chain processes, identifying areas for improvement, and implementing solutions to enhance overall supply chain performance. `,
          responsibilities:"Design, develop, and optimize supply chain processes to meet operational requirements.Collaborate with cross-functional teams to improve supply chain efficiency and reliability.Identify issues within the supply chain network and implement solutions to address them.Conduct analysis of supply chain data to identify trends and areas for improvement.Ensure compliance with industry standards, regulations, and best practices.Preare detailed technical reports and documentation related to supply chain processes.Provide technical guidance and support to manufacturing teams for process improvements.Participate in supply chain strategy development and provide feedback on process optimization.",
          education: "Requires Bachelor's or Master’s in Quality Engineering, or a related field.",
          salary: "$65,000 to $93,184.00 per year, depending on experience.",
          application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
        },
      {
          title: "Quality Assurance Engineer",
          code: "025",
          location: "100% Remote or Hybrid within the USA",
          experience: "1-7 years of experience as an Quality Assurance Engineer",
          description: `As a Supply Chain Engineer, you will play a crucial role in optimizing and managing the flow of goods, services, and information within the supply chain network. You will collaborate with cross-functional teams to ensure efficiency, reliability, and compliance with industry standards. Your responsibilities will include analyzing supply chain processes, identifying areas for improvement, and implementing solutions to enhance overall supply chain performance. `,
          responsibilities:"Design, develop, and optimize supply chain processes to meet operational requirements.Collaborate with cross-functional teams to improve supply chain efficiency and reliability.Identify issues within the supply chain network and implement solutions to address them.Conduct analysis of supply chain data to identify trends and areas for improvement.Ensure compliance with industry standards, regulations, and best practices.Preare detailed technical reports and documentation related to supply chain processes.Provide technical guidance and support to manufacturing teams for process improvements.Participate in supply chain strategy development and provide feedback on process optimization.",
          education: "Requires Bachelor's or Master’s in Quality Assurance Engineering, or a related field.",
          salary: "$65,000 to $93,184.00 per year, depending on experience.",
          application: "Please send resume with Job Code to: recruitervalidation@gmail.com"
        },
      
  ];
  
  export default jobsData;
  