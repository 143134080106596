import React from 'react'
import "../css/footer.css"
import logo from  "../images/logo.png"
import icon from  "../images/icon.png"

const footer = () => {
  return (
    <div>
      <>

  <footer className="footer-distributed">
    <div className="footer-left">
     
         <div className="navbar-left">
      <h2>Validation<span>Recruiter</span></h2>
      </div>
      
      <p className="footer-links">
        <a href="/" className="link-1">
          Home
        </a>

        <a href="/about">About</a>

        <a href="/about#contact">Contact</a>
      </p>
      <p className="footer-company-name"></p>
      <p className="footer-company-name">ValidationRecruiter © 2015</p>
    </div>

    
    <div className="footer-center">
      <h2>Headquarter</h2>
      <div>
        <i className="fa fa-map-marker" />
        <p>
          <span>Lake Shore Drive,Lake Hiawatha</span> New Jersey,07034
        </p>
       
      </div>
      <div>
        <i className="fa fa-envelope" />
        <p>
          <a href="mailto:validationrecruiter.com@gmail.com">validationrecruiter.com@gmail.com</a>
        </p>
      </div>
    </div>
    <div className="footer-right">
      <p className="footer-company-about">
        <span>About the company</span>
        At ValidationRecruiter, we understand the critical role validation engineers play in ensuring the integrity and functionality of electrical systems. That's why we're dedicated to not just filling positions, but to finding the perfect fit for both candidates and employers.
      </p>
      <div className="footer-icons">
       
        <a href="https://www.linkedin.com/company/validationrecruiter/">
          <img src={icon}/>
          <i className="fa fa-linkedin" />
        </a>

      </div>
    </div>
      
  

    


  </footer>
</>

    </div>
  )
}

export default footer