
import "../css/contact.css"

import React, { useState } from 'react';
import girl from "../images/girl.jpg"

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    comments: '',
    cv: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const validate = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = 'First name is required';
    if (!formData.lastName) errors.lastName = 'Last name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.comments) errors.comments = 'Comments are required';
    if (!formData.cv) errors.cv = 'CV is required';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setFormSubmitted(true);
      alert('Resume submitted successfully!');
      window.location.reload();
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="contact"><h2>Contact Details</h2>
    <div className="contact-container">
      
      <div className="contact-image">
        
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            {formErrors.firstName && <span>{formErrors.firstName}</span>}
          </div>
          <div>
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {formErrors.lastName && <span>{formErrors.lastName}</span>}
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && <span>{formErrors.email}</span>}
          </div>
          <div>
            <label>Comments:</label>
            <textarea
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
            {formErrors.comments && <span>{formErrors.comments}</span>}
          </div>
          <div>
            <label>Drop your CV:</label>
            <input
              type="file"
              name="cv"
              onChange={handleChange}
            />
            {formErrors.cv && <span>{formErrors.cv}</span>}
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      </div>
    </div>
  );
};

export default Contact;
