import React, { useState } from 'react';
import JobPost from './jobpost';
import jobsData from './jobdata';
import '../css/JobList.css';

const JobList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5;

  // Calculate the indices for the paginated jobs
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobsData.slice(indexOfFirstJob, indexOfLastJob);

  // Calculate total pages
  const totalPages = Math.ceil(jobsData.length / jobsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="job-list">
      <h2>Careers</h2>
      {currentJobs.map((job, index) => (
        <JobPost key={index} job={job} />
      ))}
      <div className="pagination">
        {currentPage > 1 && <button onClick={handlePrevPage}>Previous</button>}
        {currentPage < totalPages && <button onClick={handleNextPage}>Next</button>}
      </div>
    </div>
  );
};

export default JobList;
