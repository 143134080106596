import React from 'react'
import Aboutus from './Aboutus'
import "../css/about.scss"
import vali from "../images/q.jpg"

const Aboutus1 = () => {
  return (
    <div>
        <div className='blank'>
            

        </div>
        <Aboutus/>

    </div>
  )
}

export default Aboutus1