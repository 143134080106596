import React, { useState } from 'react';
import '../css/CountryBranch.scss';

const countryData = [
  {
    country: 'USA',
    branches: ['6303 Owensmouth Avenue ,10th Floor,Los Angeles, CA 91367', '640 W. California Avenue Suite 210,Sunnyvale California 94086', 
    '390 Interlocken Crescent ,Suite 350,Broomfield, CO 80021','363 North Sam Houston Pkwy E,Houston,Texas- 77060, USA','Ferncroft Corporate 35 Village Road,Suite 100,Middleton, MA 01949',
"215 South Lake Street,Suite #2 Warsaw, IN 46580",'Metro Office Park Metro Parque 7,Suite 204, St.#1 Guaynabo San Juan, 00968 Puerto Rico',
'Triad Center | 6000 Poplar Avenue Suite 250,Memphis, TN 38119 (office 238)','Quadrant | 4651 Salisbury Road,Suite 400,Jacksonville, 32256'
,'1230,Columbia St, Suite 1180, San Diego, California, 92101']
 },
  {
    country: 'Canada',
    branches: ['36 Toronto Street Suite, # 850 Toronto Ontario, M5C 2C5 Canada.']
  },
  {
    country: 'Central America',
    branches: ['06th Floor Escazu Corporate Center Escazu,San Jose 10201 Costa Rica.']
  },
  {
    country: 'Mexico',
    branches: ['Av. Paseo del Centenario 9580 Suite 901A Zona Rio, Tijuana, B.C Mexico. Cdigo Postal 22010']
  },
  {
    country: 'India',
    branches: ['1-11-251/1B,1st floor,Vasanth Towers,Begumpet , Hyderabad-500016', 'Unit-1030,Tower-A, A-40, I Thum Tower ,Sector-62, Noida,Delhi']
  },
  {
    country: 'Europe',
    branches: ['Parma,emilia romagna ,cap 431266,& Italy',' Holborn outhhampton row,London, WC1b 5HJ, UK.']
  }
];
const CountryBranch = () => {
    const [selectedCountry, setSelectedCountry] = useState(null);
  
    const handleCountryClick = (country) => {
      setSelectedCountry(country === selectedCountry ? null : country);
    };
  
    return (
      <div className="country-branch-container">
        <h2>Our Locations</h2>
        <div className="country-list">
          {countryData.map((data, index) => (
            <div
              key={index}
              className={`country ${selectedCountry === data.country ? 'selected' : ''}`}
              onMouseEnter={() => handleCountryClick(data.country)}
            >
              {data.country}
            </div>
          ))}
        </div>
        <div className="branch-list">
          {countryData.map(
            (data, index) =>
              selectedCountry === data.country && (
                <div key={index} className="branches">
                  <h3>{data.country} Branches</h3>
                  <ul className="branch-grid">
                    {data.branches.map((branch, idx) => (
                      <li key={idx}>{branch}</li>
                    ))}
                  </ul>
                </div>
              )
          )}
        </div>
      </div>
    );
  };
  
  export default CountryBranch;