import React from 'react'
import ImageSlider from './ImageSlider'
import Aboutus from './Aboutus'

const Home = () => {
  return (
    <div>
      <ImageSlider/>
      <Aboutus/>
      
      
    </div>
  )
}

export default Home