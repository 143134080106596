import React from 'react';
import Card from './Card';
import '../css/Card.scss';

import a from "../images/valid/1.jpg";
import b from "../images/valid/3.jpg";
import c from "../images/valid/ind.avif";
import d from "../images/valid/5.webp";
import e from "../images/valid/4.jpg";
import f from "../images/valid/bio.jpeg";
import g from "../images/valid/in.avif";
import h from "../images/valid/m.jpg";
import i from "../images/valid/auto.jpeg";
import j from "../images/valid/aero.jpeg";
import k from "../images/valid/sys.jpeg";
import l from "../images/valid/civil.jpeg";

const cardsData = [
  {
    id: 'card1',
    image: a,
    title: 'Validation Engineer',
    content: "We're seeking a detail-oriented Validation Engineer to ensure our products meet the highest quality standards. Apply now and help us deliver exceptional results",
  },
  {
    id: 'card2',
    image: b,
    title: 'Electrical Engineer',
    content: "Join our team! We're looking for an Electrical Engineer to design, develop, and test electrical systems.",
  },
  {
    id: 'card3',
    image: c,
    title: 'Mechanical Engineer',
    content: 'We seek a passionate Mechanical Engineer to design, develop, and improve our Mechanical Requirments',
  },
  {
    id: 'card4',
    image: d,
    title: 'Manufacturing Engineer',
    content: "Help us build the future! We're seeking a Manufacturing Engineer to join our growing team.",
  },
  {
    id: 'card5',
    image: e,
    title: 'Chemical Engineer',
    content: "We're seeking a talented Chemical Engineer to join our dynamic team. Apply your expertise to develop innovative Ideas",
  },
  {
    id: 'card6',
    image: f,
    title: 'Biomedical Engineer',
    content: 'Join our team! We are seeking a talented Biomedical Engineer to develop innovative solutions in the medical field. need Biomedical Engineer',
  },
  {
    id: 'card7',
    image: g,
    title: 'Industrial Engineer',
    content: "We're seeking a results-oriented Industrial Engineer to optimize processes, improve efficiency, and drive continuous improvement.",
  },
  {
    id: 'card8',
    image: h,
    title: 'Materials Engineer',
    content: "Join our team! We're seeking a talented Materials Engineer to develop innovative new products. Apply now!",
  },
  {
    id: 'card9',
    image: i,
    title: 'Automobile Engineer',
    content: "Are you a passionate Automobile Engineer? We're seeking a talented individual to help us [briefly describe what the engineer will be working on].",
  },
  {
    id: 'card10',
    image: j,
    title: 'Aerospace Engineer',
    content: "We're seeking a passionate Aerospace Engineer to join our team. Help us solve complex challenges and push the boundaries of aerospace.",
  },
  {
    id: 'card11',
    image: k,
    title: 'Systems Engineer',
    content: "Are you a skilled Systems Engineer? We're looking for a talented individual to join our team! Help us design, implement, and maintain our critical systems.",
  },
  {
    id: 'card12',
    image: l,
    title: 'Civil Engineer',
    content: "Join our team and shape the future of infrastructure projects. We're looking for a passionate engineer to collaborate and lead.",
  }
];

const Cardgrid = () => {
  return (
    <div className="card-grid">
      {cardsData.map((card) => (
        <Card key={card.id} id={card.id} image={card.image} title={card.title} content={card.content} />
      ))}
    </div>
  );
};

export default Cardgrid;
