import React from 'react'
import Contact from './Contact'
import "../css/about.scss"
import Card from './Cardgrid'
import Location from './Location'

const Aboutus = () => {
  return (
    <div className='about'>
    <div className='aboutus'>
      <div className='header'>
        <div className='who'>
          <h2>Who We are</h2>
          <p>Welcome to ValidationRecruiter, where excellence meets opportunity in the world of validation engineering. As industry leaders in recruitment, we specialize in sourcing and connecting top-tier validation engineers with leading companies across the USA. With a track record of successfully placing over 20,000 validation engineers, we pride ourselves on our commitment to excellence and our ability to match skilled professionals with the perfect roles to showcase their talents.</p>

<p>At ValidationRecruiter, we understand the critical role validation engineers play in ensuring the integrity and functionality of electrical systems. That's why we're dedicated to not just filling positions, but to finding the perfect fit for both candidates and employers. Our rigorous screening process ensures that only the most qualified individuals join our network, guaranteeing that our clients receive the highest caliber of talent.</p>

<p> Whether you're a seasoned validation engineer seeking new opportunities or a company in need of top-tier talent, trust ValidationRecruiter to deliver exceptional results. Join our community today and discover why thousands of validation engineers across the nation choose us as their preferred recruitment partner.</p>

        </div>
        <div className='who'>
          <h2>Engineering Training Program Opportunity</h2>
<p>
ValidationRecruiter.Com has collaborated with 500+ esteemed companies offering Free online engineering training tailored for industries such as Engineering, Medical Devices, Pharmaceuticals, Biotechnology, and more. If you're keen on seizing this opportunity, kindly share a copy of your resume with us at , 
<a href='mailto:validationrecruiter.com@gmail.com'>validationrecruiter.com@gmail.com</a> . Enroll yourself today and embark on a journey of professional development without any delay!</p>

        </div>
        <div className='footer'>
          <h2>Our Service</h2>
          <div className='skills'>
<Card/>
          </div>

        </div>

      </div>


   <Contact/>
   <Location/>
    </div>
    </div>
  )
}

export default Aboutus