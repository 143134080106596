import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../css/imageSlider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import one from "../images/2.jpg";
import two from "../images/v.jpeg";
import three from "../images/m.jpg";
import four from "../images/quality.gif";
import five from "../images/q.jpg";

const ImageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideContentVisible, setSlideContentVisible] = useState(false);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      setSlideContentVisible(false);
    },
  };

  useEffect(() => {
    setSlideContentVisible(true);
  }, [currentSlide]);

  const slidesData = [
    {
      image: one,
      header: 'Validation Engineer',
      body: '"Expert Validation Engineers Tailored for Your Success. Partner with Us for Superior Validation Solutions."',
    },
    {
      image: two,
      header: 'Electrical Engineering',
      body: '"Connecting Top Talent with Innovation: Premier Electrical Engineering Recruitment Solutions for Leading-edge Projects and Companies."',
    },
    {
      image: three,
      header: 'Mechanical Engineering',
      body: '"Unlocking Mechanical Excellence: Premier Recruiter of Talent Driving Innovation and Efficiency in Mechanical Engineering Solutions."',
    },
    {
      image: four,
      header: 'Quality Engineer',
      body: '"Precision in Quality: Our Quality Engineers Elevate Standards, Ensuring Flawless Processes and Products for Sustained Excellence."',
    },
    {
      image: five,
      header: 'Manufacturing Engineering',
      body: '"Crafting Efficiency: Specialized Manufacturing Engineering Solutions Tailored to Enhance Production Processes and Amplify Operational Performance."',
    },
  ];

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={`slide-${index}`} className="slide-image" />
            <div className='slide-content' >
              <h2 className={`slide-content1 ${slideContentVisible && currentSlide === index ? 'visible' : ''}`}>{slide.header}</h2>
              <p className={`slide-content1 ${slideContentVisible && currentSlide === index ? 'visible' : ''}`}>{slide.body}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;
