import React from 'react';
import '../css/Card.scss';

const Card = ({ image, title, content }) => {
  return (
    <div className="card">
      <div className="card-inner">
        <div className="card-front">
          <div className="card-image" style={{ backgroundImage: `url(${image})` }}>
          </div>
          <div className="card-title">{title}</div>
        </div>
        <div className="card-back">
          <h2>{title}</h2>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
