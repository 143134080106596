import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/jsx/Home";

import About1 from "./components/jsx/Aboutus1.jsx";
import Navbar from "./components/jsx/Navbar"
import Footer from "./components/jsx/Footer";
import Training from "./components/jsx/Training.jsx";
import Jobs from "./components/jsx/Jobs"
import './App.css';

function App() {
  return (
    
    <Router  >
      
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/about" element={<About1 />} />
          <Route path="/Career" element={<Jobs />} />
          <Route path="/Training" element={<Training />} />
        </Routes>
      <Footer/>
     
    </Router>
  );
}

export default App;
