import "../css/navbar.css"

import React , { useState } from 'react';
import { IoMenu } from "react-icons/io5";





const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };
  return (
    <nav className="navbar">
      <div className="navbar-left">
      <h2>Validation<span>Recruiter</span></h2>
      <a>Jobs for Engineers</a>
      </div>
      <div className="navbar-right">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/Career">Career</a>
        <a href="/Training">Free Training</a>
      </div>
      <div 
        className="menu-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <IoMenu color="black"
        size="20px"/>
        {isMenuOpen && (
          <div className="menu-tab">
            <ul>
              <li>   <a href="/">Home</a></li>
              <li> <a href="/about">About</a></li>
              <li><a href="/Career">Career</a></li>
              <li>  <a href="/Training">Free Training</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;





