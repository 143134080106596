// src/JobPost.js
import React from 'react';
import '../css/JobList.css';

const JobPost = ({ job }) => {
  return (
    <div className="job-post">
      <h2>Job Requirement: {job.title} (Job Code {job.code})</h2>
      <p><strong>Location:</strong> {job.location}</p>
      <p><strong>Experience:</strong> {job.experience}</p>
      <p><strong>Job Description:</strong> {job.description}</p>
      <p><strong>Responsibilities:</strong>
        {job.responsibilities}</p>
      <p><strong>Education:</strong> {job.education}</p>
      <p><strong>Salary Range:</strong> {job.salary}</p>
      <p><strong>Application:</strong> {job.application}</p>
    </div>
  );
};

export default JobPost;
